import axios from "axios";
import store from "@/store";
import router from "@/router/router";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL2,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const setAuthToken = (token) => {
  if (token) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete apiClient.defaults.headers.common["Authorization"];
  }
};

let isLoggingOut = false;

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401 && !isLoggingOut) {
      isLoggingOut = true;
      try {
        if (store.state.isLoggedIn) {
          await store.dispatch("logout");
          alert("Session expired. Please log in again.");
          if (router.currentRoute.value.path !== "/login") {
            await router.push("/login");
          }
        }
      } catch (error) {
        console.error("Error during logout process:", error);
      } finally {
        isLoggingOut = false;
      }
    }
    return Promise.reject(error);
  }
);
export default {
  login(username, password) {
    return apiClient.post("/auth/login", {
      username: username,
      password: password,
    });
  },

  setAuthToken,

  getStatus(windfarm) {
    return apiClient.get(`/performance/WTGs?dbName=${windfarm}`);
  },

  getLastDate(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/lastDate/${wtg}/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getWindfarms(windfarm) {
    return apiClient.get(`/performance/FARM?dbName=${windfarm}`);
  },

  getTurbines(windfarm) {
    return apiClient.get(`/performance/WTGs?dbName=${windfarm}`);
  },

  getComponents(wf) {
    return apiClient.get(`/windfarm/${wf}/components`);
  },

  getAlerts(idclient) {
    return apiClient.get(`/alerts/${idclient}`);
  },

  getLocation(location) {
    return apiClient.get(`/farm/${location}`);
  },

  getTimeSeries(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/collection/${wtg}/time/${startDate}/${endDate}?dbName=${windfarm}`
    )
  },

  //Portfolio
  getWindfarmTime(windfarm) {
    return apiClient.get(`/performance/FARM?dbName=${windfarm}`);
  },

  getWindfarmsTime(windfarm) {
    return apiClient.get(`/performance/WTGs?dbName=${windfarm}`);
  },

  getFarmTime() {
    return apiClient.get(`/performance/FARM/`);
  },

  getTurbinesTime(windfarm) {
    return apiClient.get(`/performance/WTGs?dbName=${windfarm}`);
  },

  getWindfarmsPowerCurve(wf) {
    return apiClient.get(`/windfarm/${wf}/powercurve`);
  },

  getExtremeComponent(idwfarm, wtg) {
    return apiClient.get(`/windfarm/${idwfarm}/wtg/${wtg}/max-extreme`);
  },

  getWindfarmExtremeComponent(idwfarm) {
    return apiClient.get(`/extreme/${idwfarm}`);
  },

  getExtremeVariables(wtgModel, component) {
    return apiClient.get(
      `/windfarm/wtg/model/${wtgModel}/${component}/extreme`
    );
  },

  getExtremeVariables2(idwfarm, wtg, component) {
    return apiClient.get(
      `/windfarm/${idwfarm}/wtg/${wtg}/component/${component}/extreme`
    );
  },

  getWindfarmFatigue(idwfarm) {
    return apiClient.get(`/fatigue/${idwfarm}`);
  },

  getFatigueTurbine(idwfarm, wtg) {
    return apiClient.get(`/windfarm/${idwfarm}/wtg/${wtg}/fatigue`);
  },

  getFatigueVariables(idwfarm, wtg, component) {
    return apiClient.get(
      `/windfarm/${idwfarm}/wtg/${wtg}/component/${component}/fatigue`
    );
  },

  getEnergyWR(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/windrose-energy/${wtg}/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getWfPerformance(wf) {
    return apiClient.get(`/windfarm/${wf}/performance`);
  },

  getInflowAngleWindDirection(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/inflow-wind/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getInflowAngleWindSpeed(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/inflow-windspeed/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getWtgPowerCurve(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/powercurve/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getWtgPowerCurveScada(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/powercurve-scada/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getPowerCurveOEM(model) {
    return apiClient.get(`performance/powercurve-oem/${model}`);
  },

  getPowerOutputTime(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/power-time/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getWtgRotorSpeed(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/angular/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getWtgRotorSpeedScada(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/angular-scada/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getTemperature(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/temperature/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getTurbulenceIntensityWindDirection(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/turbulence-wind/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getTurbulenceIntensityWindSpeed(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/turbulence-windspeed/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getWtgWindRose(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/windrose/${wtg}/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getWindSpeedDistribution(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/windspeed-dist/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getYawMissalignmentTime(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/yaw-time/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getYawMissalignmentWindDirection(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/yaw-wind/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getYawMissalignmentWindSpeed(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/yaw-ws/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getWtgExtreme(windfarm, wtg) {
    return apiClient.get(`/extreme/${windfarm}/${wtg}`);
  },

  getComponentExtreme(windfarm, wtg, component) {
    return apiClient.get(`/extreme/${windfarm}/${wtg}/${component}`);
  },

  getWtgFatigue(windfarm, wtg) {
    return apiClient.get(`/fatigue/${windfarm}/${wtg}`);
  },

  getComponentFatigue(windfarm, wtg, component) {
    return apiClient.get(`/fatigue/${windfarm}/${wtg}/${component}`);
  },

  getComponentExtremeByDate(
    windfarm,
    wtg,
    component,
    model,
    startDate,
    endDate,
    variables
  ) {
    return apiClient.post(
      `/extreme/${windfarm}/${wtg}/${component}/${model}/${startDate}/${endDate}`,
      { vars: variables }
    );
  },

  //CALCULATED KPIs Mongo
  getCalculatedKpis(wtg, range, windfarm) {
    return apiClient.get(`/calculated-kpis/find?wtg=${wtg}&timeRange=${range}&windFarm=${windfarm}`);
  },

  getAllCalculatedKpis(range, windfarm) {
    return apiClient.get(`/calculated-kpis?timeRange=${range}&windFarm=${windfarm}`);
  },

  //SECONDARY KPIs
  getVInVOut(wtg, startDate, endDate) {
    return apiClient.get(
      `/performance/vin-vout/${wtg}/${startDate}/${endDate}`
    );
  },
  getIceRisk(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/ice-risk/${wtg}/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },

  getNacelleAnemo(wtg, startDate, endDate) {
    return apiClient.get(
      `/performance/nacelle-anemometer/${wtg}/${startDate}/${endDate}`
    );
  },

  //SQL KPIs
  getCalculatedFarm(windfarm, range) {
    return apiClient.get(`/calculated/${windfarm}?rango=${range}`);
  },

  //KPIs
  getKpisFarm( startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/metrics/farm/${startDate}/${endDate}?dbName=${windfarm}`
    );
  },
  getKpis(wtg, startDate, endDate, windfarm) {
    return apiClient.get(
      `/performance/metrics/${wtg}_TimeSeries/${startDate}/${endDate}?dbName=${windfarm}`
    );
  }
};
